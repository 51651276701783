<template>
    <div>
        <div v-for="(item, n) in data" :key="n">
            <div v-if="item.Type === 'imagetext'">
                <image-text :data="item.Data" />
            </div>
            <div v-else-if="item.Type === 'slideshow'">
                <all-slide-show :data="item.Data" />
            </div>
            <div v-else-if="item.Type === 'gallery'">
                <gallery :data="item.Data" />
            </div>
            <div v-else-if="item.Type === 'textfield'">
                <text-field :data="item.Data" />
            </div>
            <div v-else-if="item.Type === 'datatable'">
                <data-table :data="item.Data" />
            </div>
            <div v-else-if="item.Type === 'contentcms'">
                <custom-cms :data="item.Data" />
            </div>
            <div v-else-if="item.Type === 'icontext'">
                <icon-text :data="item.Data" />
            </div>
            <div v-else-if="item.Type === 'routerview'">
                <router-view></router-view>
            </div>
            <div v-else-if="item.Type === 'oldicontext'">
                <old-icon-text :data="item.Data"></old-icon-text>
            </div>
            <!-- Elite Center Components-->
            <div :class="n === 0 ? '' : 'sm:my-2 md:my-5 my-2'" v-if="item.Type === 'product'">
                <product-detail :data="item.Data" />
            </div>
            <div :class="n === 0 ? '' : 'sm:my-2 md:my-5 my-2'" v-if="item.Type === 'header1'">
                <detail-headers :data="item.Data" />
            </div>
            <div :class="n === 0 ? '' : 'sm:my-2 md:my-5 my-2'" v-else-if="item.Type === 'schedule'">
                <schedule :data="item.Data" />
            </div>
            <div :class="n === 0 ? '' : 'sm:my-2 md:my-5 my-2'" v-else-if="item.Type === 'step'">
                <step :data="item.Data" />
            </div>
            <div :class="n === 0 ? '' : 'sm:my-2 md:my-5 my-2'" v-else-if="item.Type === 'order_summary'">
                <order-summary :data="item.Data" />
            </div>
            <div :class="n === 0 ? '' : 'sm:my-2 md:my-5 my-2'" v-else-if="item.Type === 'icon_title'">
                <icon-title :data="item.Data" />
            </div>
            <div :class="n === 0 ? '' : 'sm:my-2 md:my-5 my-2'" v-else-if="item.Type === 'text'">
                <text-component :data="item.Data" />
            </div>
            <div :class="n === 0 ? '' : 'sm:my-2 md:my-5 my-2'" v-else-if="item.Type === 'action'">
                <action :data="item.Data" />
            </div>
            <div :class="n === 0 ? '' : 'sm:my-2 md:my-5 my-2'" v-else-if="item.Type === 'list'">
                <list-history :data="item.Data" />
            </div>
            <div v-else-if="item.Type === 'h1'">
                <div class="py-2 container">
                    <h1 class="font-bold text-2xl">{{ item.Data.Description }}</h1>
                </div>
            </div>
            <!-- End Of Elite Center Components-->

            <!-- VAddress components -->
            <div v-else-if="item.Type === 'vaddress.listitem'">
                <vaddress-list-item :data="item.Data"></vaddress-list-item>
            </div>
            <div v-else-if="item.Type === 'vaddress.calltoaction'">
                <vaddress-call-to-action :data="item.Data"></vaddress-call-to-action>
            </div>
            <div v-else-if="item.Type === 'vaddress.tabs'">
                <vaddress-tabs :data="item"></vaddress-tabs>
            </div>
            <div v-else-if="item.Type === 'vaddress.home.background'">
                <vaddress-home-background :data="item.Data"></vaddress-home-background>
            </div>
            <div v-else-if="item.Type === 'vaddress.dashboard.tracking'">
                <vaddress-dashboard-tracking :data="item.Data"></vaddress-dashboard-tracking>
            </div>
            <div v-else-if="item.Type === 'vaddress.order.liveitem'">
                <vaddress-live-item :data="item"></vaddress-live-item>
            </div>
            <div v-else-if="item.Type === 'vaddress.order.imagelist'">
                <vaddress-image-list :data="item.Data"></vaddress-image-list>
            </div>
            <div v-else-if="item.Type === 'vaddress.order.filelist'">
                <vaddress-file-list :data="item.Data"></vaddress-file-list>
            </div>
            <div v-else-if="item.Type === 'vaddress.order.packageitem'">
                <vaddress-package-item :data="item.Data"></vaddress-package-item>
            </div>
             <div v-else-if="item.Type === 'vaddress.order.packageitemheader'">
                <vaddress-package-item-header :data="item.Data"></vaddress-package-item-header>
            </div>
            <div v-else-if="item.Type === 'vaddress.user.profilecard'">
                <vaddress-user-profile-card :data="item.Data"></vaddress-user-profile-card>
            </div>
            <!-- End of VAddress components -->
        </div>
    </div>
</template>
<script>
export default {
    name: 'Cms',
    props: {
        data: {
            type: Array
        }
    },
    components: {
        ImageText: () => import('@/components/imagetext'),
        AllSlideShow: () => import('@/components/slideshow'),
        Gallery: () => import('@/components/gallery'),
        TextField: () => import('@/components/textfield'),
        DataTable: () => import('@/components/datatable'),
        CustomCms: () => import('@/components/customcms'),
        IconText: () => import('@/components/newIconText'),
        OldIconText: () => import('@/components/icontext/IconText'),
        ProductDetail: () => import('@/components/Detail/Product'),
        DetailHeaders: () => import('@/components/Detail/DetailHeaders'),
        Schedule: () => import('@/components/order/schedule'),
        IconTitle: () => import('@/components/success/iconTitle'),
        TextComponent: () => import('@/components/success/text'),
        Action: () => import('@/components/success/action'),
        Step: () => import('@/components/order/steps'),
        OrderSummary: () => import('@/components/order/summary'),
        ListHistory: () => import('@/components/history/list'),

        // Import VAddress components
        VaddressListItem: () => import('@/components/vaddress/ListItem'),
        VaddressCallToAction: () => import('@/components/vaddress/CallToAction'),
        VaddressHomeBackground: () => import('@/components/vaddress/home/Background'),
        VaddressDashboardTracking: () => import('@/components/vaddress/dashboard/Tracking'),
        VaddressTabs: () => import('@/components/vaddress/Tabs'),
        VaddressLiveItem: () => import('@/components/vaddress/order/LiveItem'),
        VaddressImageList: () => import('@/components/vaddress/order/ImageList'),
        VaddressFileList: () => import('@/components/vaddress/order/FileList'),
        VaddressPackageItem: () => import('@/components/vaddress/order/PackageItem'),
        VaddressPackageItemHeader: () => import('@/components/vaddress/order/PackageItemHeader'),
        VaddressUserProfileCard: () => import('@/components/vaddress/user/UserProfileCard')
        // End of import VAddress components
    }
}
</script>
